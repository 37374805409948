@import "../../layout/styles/variables.scss";

.ModalBox {
  background: rgba(35, 40, 47, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal {
  background: $color-background-2;
  width: 636px;
  padding: 24px 32px;
  position: relative;
  max-width: 75%;

  @media screen and (max-width: 320px) {
    padding: 15px 32px;
  }
}

.ModalCloseWrapper {
  cursor: pointer;
  position: absolute;
  right: 22px;
  padding: 10px;
  top: 18px;
  color: $color-resources;

  @media screen and (max-width: 320px) {
    top: 9px;
  }
}