.card {
  position: relative;
  padding: 15px 20px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #fff;
  overflow: hidden;

  @media all and (max-width: 767px){
    padding: 30px;
  }

  &::before{
    position: absolute;
    top: -57px;
    left: -66px;
    content: '';
    width: 240px;
    height: 60px;
    border: 24px solid #a8c9dc;
    border-radius: 88.5px;
    opacity: 0.12;
  }

  .cardBody{
    margin-left: 0;
    width: 100%;

    .cardTitle {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      color: #162844;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .label{
        width: 48px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        border-radius: 12px;
        background: #ffcd00;
        color: #2C303F;
        font-size: 10px;
      }
    }
  }
}

.bookBtnGroup{
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;

  .bookBtn{
    display: flex;
    align-items: center;
    background: #009AF1;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 10px 15px;
    white-space: nowrap;
    margin-right: 10px;
    transition: 0.3s;

    &:last-child{
      margin-right: 0;
    }

    .bookBtnIcon{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    &:hover{
      background: darken(#009AF1,10%)
    }
  }
}



