@import '../../layout/styles/variables';

@keyframes openButtonAnimation {
  0% {
    transform: rotate(315deg);
  }

  100% {
    transform: rotate(135deg);

  }
}

@keyframes closeButtonAnimation {
  0% {
    transform: rotate(135deg);
  }

  100% {
    transform: rotate(315deg);

  }
}


.ReactCollapse--collapse {
  transition: height 200ms;
  grid-column-start: 1;
  grid-column-end: 4;

  &.collapseDesktop {
    @media (max-width: 800px) {
      display: none;
    }
  }

  &.collapseMobile {

    @media (min-width: 801px) {
      display: none;
    }

    @media (min-width: 601px) and (max-width: 800px) {
      margin: 0;
      width: 100%;
    }
  }

  &.none {
    display: none;
  }
}

.ReactCollapse--content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
  padding-top: 30px;

  @media (max-width: 800px) {
    padding: 0 20px;

  }

  &.collapseDesktop {
    @media (max-width: 800px) {
      display: none;
    }
  }

  &.collapseMobile {
    flex-basis: 100%;

    @media (min-width: 801px) {
      display: none;
    }

    @media (max-width: 361px) {
      padding: 0;
    }

  }

}


.employeeCard {
  width: 100%;
  min-width: 100%;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #E5E9EE;

  mark {
    padding: 0;
    background-color: gold;
  }

  .row {
    position: relative;
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-flow: row;
    padding: 20px;

    @media (max-width: 801px) {
      flex-flow: row wrap;
    }

    @media (max-width: 601px) {
      display: grid;
      grid-template-columns: 0.2fr 1fr;
      padding: 20px 5px;
    }

    @media (max-width: 361px) {
      padding: 20px 30px 20px 40px;
    }


    .profile-image {
      margin-right: 20px;

      .avatarButton {
        outline: none;
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;

        &:focus {
          outline: none;
        }

        .image-wrapper {
          width: 100px;
          height: 100px;
        }
      }
    }

    .profile-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 10px 0 14px;
      width: 100%;

      @media (max-width: 801px) {
        width: 80%;
      }

      @media (max-width: 601px) {
        grid-template-columns: 1fr;
        width: 100%;
      }

      @media (max-width: 361px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }


      .name-and-title-container {
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        .name-container {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;

          .fullNameButton {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            padding: 0;
            display: inline-flex;
            flex-flow: row-reverse;
            align-items: center;
            vertical-align: top;

            .award-container {
              max-height: 20px;
              margin-right: 12px;
              .award-picture {
                max-height: 20px;
              }
            }

            span {
              font-family: 'Open Sans', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 20px;
              color: #162844;
            }

          }
        }

        .title {
          margin-bottom: 10px;

          span {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #162844;
          }
        }

        .department-name span {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          text-transform: uppercase;
          color: $color-font-3;
          opacity: 0.8;
        }

        .leave-event {
          display: inline-block;
          font-size: 12px;
          font-weight: 700;
          width: 100%;
          text-transform: uppercase;
          margin-top: 18px;
        }

        .leave-unavailable {
          display: flex;
          background-color: #228ECE;
          padding: 8px;
          margin: 0;
          color: white;
          align-items: center;

          &>p {
            margin: 0;
            display: inline-block;
            margin-left: 8px;
          }
        }

        .leave-date {
          display: flex;
          align-items: center;
          background-color: #F6F8FA;
          padding: 8px;
          color: #3C4752;
          margin: 0;

          &>img {
            opacity: 0;
          }
        }
      }


      .contacts {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        @media (min-width: 801px) {
          margin-left: 17px;
        }

        @media (max-width: 601px) {
          margin-top: 10px;
        }

        .eMail {
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-items: center;
          margin: 0 0 10px;
          max-width: max-content;


          a {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #162844;

            &:hover {
              color: #22A2DE;
            }

            .email-icon {
              margin-right: 11px;
            }
          }
        }

        .phoneNumber {
          margin: 0 0 10px;
          display: flex;
          align-items: center;
          max-width: max-content;

          a {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #162844;

            &:hover {
              color: #22A2DE;
            }

            .phone-icon {
              margin-right: 11px;
            }
          }

          .extension {
            color: #3C4752;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            line-height: 19px;
          }

        }


        .locationContainer {
          display: flex;
          flex-flow: row;
          align-items: center;

          .locationLink {

            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: flex-start;

            .locationIcon {
              margin-right: 11px;
              max-height: 17px;

            }

            .locationText {
              margin: 0;
              padding: 0;
              font-family: "Open Sans", sans-serif;
              color: #3C4752;
              font-size: 14px;
              line-height: 19px;
            }

            &:hover {
              .locationText {
                color: #22A2DE;
              }
            }
          }

          .homeText {
            margin: 0;
            padding: 0;
            font-family: "Open Sans", sans-serif;
            color: #3C4752;
            font-size: 14px;
            line-height: 19px;
          }

          .homeIcon {
            margin-right: 11px;
            max-height: 17px;
            padding-bottom: 2px;
          }
        }


      }

    }


  }


}


.employeeCard .triggerContainer {
  flex-flow: row;
  float: right;
  position: absolute;
  right: 7px;
  display: flex;
  top: 50%;

  @media (max-width: 500px) {
    right: 20px;
    top: 80px;
  }

  @media (max-width: 411px) {
    right: 20px;
  }

  @media (max-width: 321px) {
    top: 50px;
  }

  &.triggerDesktop {
    @media (max-width: 501px) {
      display: none !important;
    }
  }

  &.triggerMobile {
    @media (min-width: 500px) {
      display: none !important;
    }
  }

}

.openButton {
  display: inline-block;
  margin-bottom: 5px;
  width: 12px;
  height: 12px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-color: #3C4752;
  cursor: pointer;
  transform: rotate(315deg);
}

.openButtonOpen {
  transform: rotate(135deg);
  animation: openButtonAnimation 0.5s;
}

.openButtonClose {
  transform: rotate(315deg);
  animation: closeButtonAnimation 0.5s;
  border-color: #AEADB1;
}

.copyToClipboardBtn {
  outline: none !important;

  @media (max-width: 1023px) {

    background: transparent;
    border: none;
    align-items: center;
    width: 20px;
    height: 20px;

  }

  @media (max-width: 1023px) {
    display: none;

  }

  @media (min-width: 1024px) {

    background: transparent;
    border: none;
    align-items: center;
    width: 20px;
    height: 17px;
  }
}

.copyToClipboardBtn:focus {
  outline: none !important;

}

.clipboardIcon {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  outline: none;
  margin: 0;
  padding: 0;
}

.onButton {

  transform: translateX(0);
  opacity: 1;

}

.offButton {

  transform: translateX(-10px);
  opacity: 0;
}

#tooltip-name,
#tooltip-email,
#tooltip-phone {
  padding: 10px 5px;
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;

  &.show {
    opacity: 0.9;
  }

  &.fade {
    transition: opacity 0.15s linear;
  }

  .tooltip-inner {
    background-color: #3C4752 !important;
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    border-radius: 0.25rem;
  }

  .arrow {
    position: absolute;
    display: block;
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }

  .arrow::before {
    border-right-color: #3C4752;
    position: absolute;
    content: "";
    border-style: solid;
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
  }

}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
}

p.leave-date-text {
  margin: 0;
  display: inline-block;
  margin-left: 8px;
  font-weight: 700;
  white-space: pre-wrap;
}

p.contact-the-deputy {
  color: #56646F;
  margin: 0 !important;
  font-weight: 400 !important;
  display: inline-block;
}

.award-banner {
  position: absolute;
  width: 110%;
  left: -5%;
  bottom: 0;
  height: 20%;
  background-repeat: no-repeat;
  .award-banner-text {
    margin: 0;
    color: white;
    font-size: 10px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    line-height: 20px;
  }
}