@import "../../layout/styles/variables";

.Content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 38px 0;
  color: $color-font-3;
  text-align: center;
  font-size: 14px;
  line-height: 24px;

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }

  @media screen and (max-width: 320px) {
    padding: 14px 0 0;
  }
}

.Header {
  font-size: 18px;
  line-height: 24px;
  color: $color-font-1;
  font-weight: 600;
}

.HelpDeskButton {
  background: $color-accent-1;
  color: white !important;
  width: 200px;
  border: none;
  padding: 0;
  margin: 16px 0 24px 0;
  font-size: 14px;

  &:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-accent-1 no-repeat center;
  }
}

.HelpDeskLink {
  color: white;
  width: 100%;
  height: 100%;
  display: block;
  padding: 12px 0;
}

.Offices {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 52px);
  margin: 0 26px;

  @media screen and (max-width: 840px) {
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }
}

@mixin offices {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0;
}

.Office {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  height: 74px;

  @media screen and (max-width: 600px) {
    width: 50%;
  }
}

.OfficeName {
  @include offices;

  color: $color-font-3;
  font-weight: 600;
  text-decoration: none;
}

.OfficePhoneLink {
  @include offices;
  white-space: nowrap;
  display: block;
  color: $color-accent-1;
  padding: 8px 0;
  text-decoration: none;

  &:hover {
    color: $color-accent-hover;
  }
}

.OfficeExt {
  @include offices;
}

.OfficePhoneRowsContainer {
  display: flex;
  flex-direction: column;
  width: 70%;

  @media screen and (max-width: 600px){
    width: 100%;
  }
}

.OfficePhoneRows{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
