.results {
  max-width: 780px;
  width: 780px;
  height: fit-content;
  margin: 0 auto;
  padding: 20px 0;
  text-align: unset;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
    padding: 20px 20px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    margin: 0;
    padding: 20px 0;
  }
}


#contained-modal-title-vcenter {
  color: #3C4752;
  font-family: "Open Sans";
  font-weight: 600;
  padding-left: 30px;
}

.custom-modal {
  width: 100vw;
}

.closeModalButton {
  background: #fff;
  color: #27ABE2;
  border: 2px solid #27ABE2;
  border-radius: 3px;
  padding: 5px 10px;
}

#no-results-found-message {
  p {
    margin: auto;
    min-width: 50px;
    padding-top: 20px;
    color: #3C4752;
    font-family: "Open Sans";
  }
}

#user-friendly-search {
  margin-top: 40px;
  padding-bottom: 40px;


  img {
    height: 35vh;
    margin: 0 auto;
    display: block;
    padding-bottom: 20px;
  }

  p {
    max-width: 780px;
    color: #3C4752;
    font-family: "Open Sans";
  }



  @media only screen and (max-width: 750px) {
    img {
      max-width: 100%;
      max-height: 200px;
      min-height: 200px;
      min-width: 200px;
    }

    br {
      display: none;
    }
  }
  @media only screen and (max-height: 500px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {

    img {
      min-height: unset;
      min-width: unset;
    }

  }
}

#backToTop {
  position: fixed;
  right: 30px;
  bottom: 40px;
  z-index: 99;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
  width: 50px;
  height: 42px;
  border-radius: 2px;
  background-color: #858585;
  opacity: 0.8;
  -webkit-transition-delay: 0s;
  transition: background .15s ease-in-out 0s;
  cursor: pointer;
  outline: none;

  @media all and (min-width: 1024px) {
    &:hover {
      background-color: #2E83B9;
    }
  }

  @media all and (max-width: 1024px) and (min-height: 600px) {
    & {
      bottom: 200px;
    }
  }

  @media all and (max-width: 600px) {
    & {
      bottom: 15px;
    }
  }

  &::before {
    content: '⌃';
    color: whitesmoke;
    align-self: baseline;
    font-size: 40px;
    height: inherit;
  }

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: rgba(0, 0, 0, 0) !important;
    outline: none;
  }
}

@media only screen and (min-height: 700px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 0)  {

  #backToTop:not(:root:root) {
    bottom: 70px;
  }


  }

@media only screen and (max-width: 800px) and (min-height: 1024px) {

  #user-friendly-search p {
    max-width: 695px;
  }
}

@media only screen and (max-width: 602px) and (max-height: 960px) {

  #user-friendly-search p {
    max-width: 90vw;
  }
}

@media screen and (orientation: landscape) and (max-height: 600px) and (min-aspect-ratio: 13/9) {
  #user-friendly-search {
    p {
      max-width: 90vw;
    }
  }
}
