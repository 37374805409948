.scroll {
  opacity: 0.3;
  background-color: aqua;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  border: none;
  z-index: 999;

  &:hover {
    opacity: 1;
  }
}


.backToTop {
  position: fixed;
  right: 30px;
  bottom: 40px;
  z-index: 99;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
  width: 50px;
  height: 42px;
  border-radius: 2px;
  background-color: #858585;
  opacity: 0.8;
  -webkit-transition-delay: 0s;
  transition: background .15s ease-in-out 0s;
  cursor: pointer;
  outline: none;

  @media all and (min-width: 1024px) {
    &:hover {
      background-color: #2E83B9;
    }
  }

  @media all and (max-width: 1024px) and (min-height: 600px) {
    & {
      bottom: 200px;
    }
  }

  @media all and (max-width: 600px) {
    & {
      bottom: 15px;
    }
  }

  &::before {
    content: '⌃';
    color: whitesmoke;
    align-self: baseline;
    height: 17px;
    font-size: 40px;
  }

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: rgba(0, 0, 0, 0) !important;
    outline: none;
  }
}

@media only screen and (min-height: 700px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {

  .backToTop:not(:root:root) {
    bottom: 70px;
  }


}

@media only screen and (max-height: 200px) {

  .backToTop {
    display: none;
  }
}