@import "../../layout/styles/variables";

.homeContainer {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  .tiles-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
            "employees"
            "handbook"
            //"timeOff"
            "booking"
            "news"
            //"office"
            "helpdesk"
            "policy"
            "company"
            "otherResources"
    ;
    grid-gap: 10px;
    max-width: 300px;
    margin: 40px auto;


    @media all and (min-width: 768px){
      grid-template-columns: repeat(2, 1fr);
      margin: 40px  auto;
      grid-template-areas:
            "employees handbook"
            "helpdesk booking"
            "news otherResources"
            "policy otherResources"
            "company company"
    ;
      max-width: 760px;
    }

    @media all and (min-width: 1025px){
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
            "employees handbook timeOff"
            "news office booking"
            "helpdesk policy otherResources"
            "company company otherResources"
      ;
      max-width: 920px;
    }
  }

}

.employees { grid-area: employees; }
.handbook { grid-area: handbook; }
.timeOff { grid-area: timeOff; }
.news { grid-area: news; }
.office { grid-area: office; }
.booking { grid-area: booking; }
.otherResources { grid-area: otherResources; }
.helpdesk { grid-area: helpdesk; }
.policy { grid-area: policy; }

.otherResourcesTileContainer{
  background: #FFFFFF;
}


.otherResourcesTitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  color: #162844;
  margin: 30px 30px 15px;
  padding: 0;
}


.card-columns {
  max-width: 760px;
  margin: 20px auto 20px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;

  @media screen and (max-width: 1023px){

    grid-template-columns: 1fr;
  }

}

.searchContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: -90px 0 0;
  padding: 0;
  z-index: 10;
  background-color: #f5f5f5;
  min-width: 98vw;
  min-height: calc(100vh - 170px);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.searchButton, .closeSearchButton{
  height: 30px;
  width: 30px;
  border: none !important;
  outline: none !important;
}

.searchButton {
  background: url("../../layout/images/Search.svg") no-repeat center;
  margin-right: 16px;
}

.closeSearchButton {
  background: url("../../layout/images/Close.svg") no-repeat center;
  padding-right: 100px;
  position: absolute;
  right: 10px;

  @media screen and (min-width: 411px) and (max-width: 600px){
    padding: 0;
    right: 15%;
  }

  @media screen and (max-width: 410px){
    padding: 0;
    right: 10%;
  }

  @media screen and (max-width: 321px){
    right: 20px;
  }
}

.searchForm {
  max-width: 760px;
  width: 760px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 20px;
  display: none;


  form {
    width: 100%;
    display: inline-flex;
    align-items: center;
    background: white;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
    position: relative;
  }

  input {
    width: 100%;
    border-radius: 0;
    padding: 10px 12px;
    color: #3C4752;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 19px;
    border: none;
    outline: none !important;
    box-shadow: none;
    position: relative;
  }

  &.trans {
    width: 100%;
    max-width: unset;
    padding: 0;
    margin-bottom: 90px;
    margin-top: 0;
    display: block;

    input {
      max-width: 780px;
      margin: auto;
      border: 1px solid #E7EBF0 !important;
    }

    form {
      height: 60px;
      padding: 10px 0;
      background: #F7F9FB;
      box-shadow: none;
    }

  }

  @media screen and (max-width: 1023px){

    max-width: 360px;
    width: 100%;

    &.trans {
      input {
        width:70%;
      }
    }

  }

  @media screen and (max-width: 600px) {
    max-width: 300px;
    input {
      width: 100%;
    }
    &.trans {
      input {
        max-width: 300px;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 350px) {
    max-width: 290px;
  }

  @media screen and (max-width: 321px) {
    max-width: 260px;
    &.trans {
      input {
        max-width: 270px;
      }
    }
  }
}

.OpenModal {
  display: none;
}



