@import "../../styles/variables";

.layout {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  position: relative;

  main {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background: #ECF0F4;
  }

  @supports (-moz-appearance:meterbar) {

    main {

      background-size: 50%;

      main::before,
      main::after {
        box-sizing: unset;
      }
    }
  }

}


@media only screen and (min-width: 768px) and #{$iPad-pro-screen-max}  {
  .layout {
    main {
      background-size: 90%;
    }
  }

  .open {

    min-height: 93vh !important;
  }

}

@media only screen and (min-width: 700px) and (max-height: 800px) {
  .layout {

    .open {
      top: 0;
      bottom: 0;
      max-height: 85vh;
    }
  }
}

@media screen and (min-width: 750px) and (max-height: 415px){
  .layout:not(:root:root){
    width: 100vw;
  }
}


