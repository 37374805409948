/**
 * @author Razvan Rauta
 * 30/06/2020
 * 12:10
 */

.otherResourcesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: #FFFFFF;
  padding: 20px;

  &.dropdown {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  &.gridTile {
    padding: 0 20px;
    grid-row-gap: 20px;
  }
}

.link {
  display: flex;
  justify-content: center;
  flex-flow: row;
  align-items: center;

  &:hover {
    background: rgba(242, 243, 246, 0.5);
    border-radius: 2px;
  }
}

.resourceCard {
  max-width: fit-content;
  max-height: fit-content;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;

}


.resourceText {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #AEADB1;
  margin: 10px 0 0;
  padding: 0;
}