$color-white: white;
$color-black: black;
$color-gray-1: #D3D3D3;
$color-gray-2: #999;
$color-shadow: rgba(128, 130, 132, 0.5);

$color-font: #4E555A;
$color-font-1: #3C4752;
$color-font-2: #A8ABAB;
$color-font-3: #56646F;
$color-accent: #27ABE2;
$color-accent-1: #009AF1;
$color-accent-hover: #007bc0;
$color-border: #EEF3F8;
$color-placeholder: #BEC7CE;
$color-background-1: #228ECE;
$color-background-2: #FFFFFF;
$color-button: #172031;

$color-footer-bg: #23282f;
$color-footer-text: #858585;

$card-text-color: #162844;

$color-resources: #AEADB1;

$color-error: #C8535D;

$phone-screen: (max-width: 601px);

$tablet-screen-min: (min-width: 768px);
$tablet-screen-max: (max-width:1023px);

$iPad-pro-screen-min: (min-width:1023px);
$iPad-pro-screen-max: (max-width:1024px);
