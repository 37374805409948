@import url('https://fonts.googleapis.com/css?family=Fira+Sans');
/*Variables*/
$background_color: #353b48;
$font: "Fira Sans", sans-serif;
$white: #f5f6fa;
$black: #0C0E10;
$gray: #202425;
$blue: #446182;
//Mixins
@mixin smallscreens {
  @media (max-width: 770px) {
    @content; } }
.errorPage {
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100vw;
  font-family: $font;
  color: white; }


.background {
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: #F0F3F7; }

.errorContainer {
  position: relative;
  margin: auto;
  width: 90%;
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  @include smallscreens {
    flex-direction: column;
    padding-bottom: 0vh; } }

.heading {
  text-align: center;
  font-size: 9em;
  line-height: 1.3em;
  margin: 2rem 0 0.5rem 0;
  padding: 0;
  text-shadow: 0 0 1rem #fefefe;
  @include smallscreens {
    font-size: 7em;
    line-height: 1.15;
    margin: 0; } }

.picture {
  display: block; }

.subheading {
  text-align: center;
  max-width: 480px;
  font-family: "Open Sans", sans-serif;
  font-size: 2em;
  line-height: 2em;
  padding: 0 1rem;
  margin: 42px auto 36px;
  font-weight: bold;
  color: #CDD4DF;
  @include smallscreens {
    font-size: 1.3em;
    line-height: 1.15;
    max-width: 100%; } }

.inner-content {
  margin: auto;
  width: 90vw; }

.home {
  display: inline-block;
  width: 175px;
  height: 40px;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  border: 2px solid #22A2DE;
  text-align: center;
  font-size: 14px;
  line-height: 40px;
  font-weight: 700;
  transition-duration: 0.4s;
  text-decoration: none;
  color: #22A2DE; }

.home:hover {
  cursor: pointer;
  color: #FFFFFF;
  background-color: #22A2DE;
  text-decoration: none; }
