/**
 * @author Razvan Rauta
 * 20/01/2020
 * 11:41
 */
@import "../../layout/styles/styles";

.jiraButton{
  @include help-button;

  top: calc(50%);

  @media screen and (max-width: 450px) {
    top: 70%;
  }

}
