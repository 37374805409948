@import "../../layout/styles/styles";

.EmergencyButton {
  @include help-button;

  top: calc(50% + 135px);

  @media screen and (max-width: 450px) {
    top: calc(70% + 135px);
  }
}