@import "./variables";

@mixin help-button {
  z-index: 20;
  position: fixed;
  background: $color-accent-1;
  padding: 10px 15px;
  border: none;
  font-weight: bold;
  color: white !important;
  display: block;
  white-space: nowrap;
  text-decoration: none !important;
  font-family:"Open Sans", sans-serif;
  font-size: 12px;
  border-radius: 5px 5px 0 0 ;
  left: -72px;
  transform: rotate(90deg);
  transform-origin: top right;
  outline: none;
  width: 105px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-accent-1 no-repeat center;
  }
}