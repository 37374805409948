/**
 * @author Razvan Rauta
 * 27/05/2020
 * 12:00
 */
@import '../../layout/styles/variables';

.projectsAndDepartmentsOuterContainer{
  display: grid;
  border-top: 2px solid #E7EBF0;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  @media (max-width: 601px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.projectsAndDepartmentsContainer {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  grid-column-gap: 34px;
  cursor: default;
  @media (max-width: 601px) {
    grid-template-columns: 1fr;
  }

}

.projectReportingSection {
  margin-top: 20px;
  width: 100%;
  max-width: 260px;


}

.departmentReportingSection,
.portfolioReportingSection {
  margin-top: 20px;
  width: 100%;
  max-width: 260px;



}

.projectManagementSection {
  margin-top: 20px;
  width: 100%;
  max-width: 260px;



}

.reportsToSection {
  margin-top: 20px;
  width: 100%;
  max-width: 260px;



}

.project {
  background: #F2F5F8;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);

  &.employee {
    cursor: pointer;
    background: rgba(242, 245, 248, 0.7)
  }

  &.employee:hover {
    background: rgba(242, 245, 248, 1);

    .managerName {
      color: #22A2DE;;
    }
  }
}

.managerPictureContainer {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.managerPicture {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  transition: transform 500ms;
}

.sectionTitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  color: $color-font-3;
  margin: 0 0 10px;
}

.managerDetailsContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
}

.managerName {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #162844;
}

.projectNameContainer {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 2px;
  align-items: center;
  justify-content: flex-start;
}

.projectName {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: $color-font-3;
}

.titlesDivider {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: $color-font-3;
  margin: 0 5px;

  &:last-of-type {
    display: none;
  }
}

.managerPosition {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  color: #162844;
}
.departmentName {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: $color-font-3;
}

.projectsOfManager{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
  .projectOfManagerName{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: #162844;
    padding: 4px 11px;
    background: #F2F5F8;
    margin: 0 10px 10px 0;
  }
}

$badgeSize-lg: 60px;
$badgeSize-sm: 80px;

.badgesContainer {
  margin-top: 20px;
  cursor: default;

  .badges {
    display: grid;
    grid-template-columns: repeat(4, $badgeSize-lg);
    grid-row-gap: 10px;

    .imgBx {
      display: block;
      width: $badgeSize-lg;
      height: $badgeSize-lg;

      > img {
        width: auto;
        height: 100%;
        //image-rendering: crisp-edges;
        //image-rendering: pixelated;
      }
    }

    @media (max-width: 601px) {
      grid-template-columns: repeat(3, $badgeSize-sm);

      .imgBx {
        width: $badgeSize-sm;
        height: $badgeSize-sm;
      }
    }
  }

  .badgesWithoutImg {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    > div {
      margin-top: 5px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #56646F;
    }
  }
}