.cardLink, .employees {
  background: #FFFFFF;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  height: 215px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  &:hover{
    .cardIcon{
      transform: scale(1.1);
    }
  }
  @media all and (max-width: 1024px){
    &.timeOff,&.office{
      display: none;
    }
  }
}

.cardTextInRow{
  height: 140px;
}

.companyStructure{
  //grid-column-start: 1;
  //grid-column-end: 3;
  //
  //@media all and (max-width: 950px){
  //  grid-column-end: 1;
    height: 220px;
  //}
  @media all and (min-width: 768px){
    grid-column-start: 1;
    grid-column-end: 3;
    height: auto;

    >.card {
      flex-flow: row;
      align-items: center;

      >img{
        margin-bottom: 0;
      }
    }
  }
}

.cardTextInRow > div{
  flex-flow: row;
  align-items: center;
}

.cardTextInRow > div > div{
  margin-left: 20px;
}

.cardTextInRow > div > div > p{
  margin: 0;
}

.companyStructure > div{
  img {
    width: 260px;
    height: 92px;

    @media all and (max-width: 950px){
      margin-bottom: 20px;
      width: 240px;
    }
  }
  @media all and (max-width: 950px){
    flex-flow: column;
    align-items: flex-start;
  }
}

.employees {
  background: url('../../assets/images/homeTiles/bg1.svg') no-repeat center;
  &:hover{
    background: linear-gradient(
                    rgba(0, 0, 0, 0.1),
                    rgba(0, 0, 0, 0.1)
    ), url('../../assets/images/homeTiles/bg1.svg') no-repeat center;
    .cardIcon{
      transform: scale(1.05);
    }
  }

  @media all and (max-width: 1025px){
    background-size: cover;
  }
}

.handbook {
  background: url('../../assets/images/homeTiles/bg2.svg') no-repeat center;
  &:hover{
    background:linear-gradient(
                    rgba(0, 0, 0, 0.1),
                    rgba(0, 0, 0, 0.1)
    ), url('../../assets/images/homeTiles/bg2.svg') no-repeat center;
    .cardIcon{
      transform: scale(1.05);
    }
  }
  @media all and (max-width: 1025px){
    background-size: cover;
  }
}

.timeOff {
  background: url('../../assets/images/homeTiles/bg3.svg') no-repeat center;
  &:hover{
    background:linear-gradient(
                    rgba(0, 0, 0, 0.1),
                    rgba(0, 0, 0, 0.1)
    ), url('../../assets/images/homeTiles/bg3.svg') no-repeat center;
    .cardIcon{
      transform: scale(1.05);
    }
  }
  }

.card {
  padding: 30px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.cardIcon {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  transition: transform .2s linear;
}

.cardTitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #172031;
  margin: 15px 0 0;
  padding: 0;
}

.cardDescription {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #172031;
  margin: 5px 0 0;
  padding: 0;
  max-width: 200px;
}

.employees,
.handbook,
.timeOff {
  .cardTitle {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .cardDescription {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }
}
.chatAI {
  .cardTitle {
    color: #228ECE;
  }
}
