@mixin loader-mixin {
  background: #27ABE2;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

;

@mixin active-styles-mixin {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

@mixin in-active-styles-mixin {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

#loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.46);
  @media only screen and (max-width: 1024px) {
    background-color: #fff;
  }
}

#loader {
  display: block;
  color: #27ABE2;
  text-indent: -9999em;
  margin: 88px auto;
  position: fixed;
  top: 42vh;
  left: 50vw;
  z-index: 99;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  @include in-active-styles-mixin;
  @include loader-mixin;

  &:before,
  &:after {
    @include loader-mixin;
    position: absolute;
    top: 0;
    content: '';
  }

  &:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &:after {
    left: 1.5em;
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  &.active {
    @include active-styles-mixin;
  }
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
