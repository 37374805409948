a {
  text-decoration: none;
}
body{
  font-family: "Open Sans", sans-serif;
}

.IE {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  z-index: 100;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.tileMe li {
  display: inline;
  float: left;
  margin-right: 20px;
}

.tile {
  color: grey;
  line-height: 42px;
  font-size: 15px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
}

.ieIcon {
  max-height: 20px;
}

//Style for FireFox
@supports (-moz-appearance:meterbar) {

  -moz-transform: scale(0.8);

  *,
  *::before,
  *::after {
    box-sizing: content-box;
  }

}

/* Chrome/Opera/Safari */
::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  color: #BEC7CE;
}

/* Firefox 19+ */
::-moz-placeholder {
  font-family: "Open Sans", sans-serif;
  color: #BEC7CE;
}

/* IE 10+ */
:-ms-input-placeholder {
  font-family: "Open Sans", sans-serif;
  color: #BEC7CE;
}

/* Firefox 18- */
:-moz-placeholder {
  font-family: "Open Sans", sans-serif;
  color: #BEC7CE;
}

::-ms-clear {
  display: none;
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type="text"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type="text"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type="text"] {
    font-size: 16px;
  }
}

